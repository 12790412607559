import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CurrencyFormat = _resolveComponent("CurrencyFormat")!
  const _component_DateTimeFormat = _resolveComponent("DateTimeFormat")!

  return (_ctx.field.fieldType ==='LINK')
    ? (_openBlock(), _createElementBlock("span", {
        key: 0,
        class: "link-primary cursor-pointer hover",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadData(_ctx.field.id,_ctx.field.objectType)))
      }, _toDisplayString(_ctx.field.value), 1))
    : (_ctx.field.fieldType ==='AMOUNT')
      ? (_openBlock(), _createBlock(_component_CurrencyFormat, {
          key: 1,
          amount: _ctx.field.value,
          currency: _ctx.field.id
        }, null, 8, ["amount", "currency"]))
      : (_ctx.field.fieldType==='DATETIME')
        ? (_openBlock(), _createBlock(_component_DateTimeFormat, {
            key: 2,
            date: _ctx.field.value
          }, null, 8, ["date"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.field.value ?_ctx.field.value:''), 1))
}