
import { defineComponent } from "vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import { LoadPanel } from "@/core/composite/composite";

export default defineComponent({
  name: "ReportField",
  components: {DateTimeFormat, CurrencyFormat},
  props: {
    field: {}
  },
  setup() {
    return {
      ...LoadPanel()
    }
  }
})
