import {BaseService} from "@/core/services/BaseService";
import {generatorQueryString} from "@/core/helpers/functions";

class ReportService extends BaseService<any> {
  endpoint = '/report'

  async getReport(code: string, data: any[], paginate: any) {
    const queryString = generatorQueryString(paginate);
    return this.http().post(this.endpoint + '/' + code + "?" + queryString, data).then(res => {
      return res.data
    })
  }

  async export(code: string, data: any[]) {
    return this.http().post(this.endpoint + '/' + code + "/export", data, {responseType: "blob"}).then(res => {
      return res
    })
  }
}

export default new ReportService()