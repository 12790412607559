
import {defineComponent, ref} from "vue";
import ReportService from "@/core/services/ReportService";
import Entities from "@/components/base/common/Entities.vue";
import ReportField from "@/views/report/ReportField.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import {CompanySelect} from "@/core/composite/composite";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import Swal from "sweetalert2";
import {makeDownloadLink} from "@/core/helpers/functions";
import QuickAction from "@/components/base/action/QuickAction.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import {IFilter} from "@/core/entity/IFilter";
import Pagination from "@/components/base/paging/Pagination.vue";

export default defineComponent({
  name: "Report",
  components: {Pagination, DateTimeSelect, QuickAction, BaseSelect, EntityLoading, ReportField, Entities},
  props: {
    code: {type: String, required: true}
  },
  setup(props) {

    const paginate = ref<IFilter>({
      paginationPage: 1,
      paginationSize: 100,
      key: {sort: 'paidDt', order: 'asc'},
    })


    const filter = ref<any[]>([])
    const loading = ref(true);
    const reportData = ref<any>({});
    const loadInputs = (code) => {
      ReportService.get<any>(code).then(res => {
        filter.value = res;
      })
    }
    const loadReport = (code: any, filter: any[], paginate: any) => {
      loading.value = true
      ReportService.getReport(code, filter, paginate).then(res => {
        reportData.value = res;
        setCurrentPageBreadcrumbsWithParams(res.title,
          [
            {link: false, router: '', text: res.title}
          ]
        )
      }).finally(() => {
        loading.value = false;
      })
    }
    const handleUpdatePage = (event) => {
      paginate.value.paginationPage = event;
      loadReport(props.code, filter.value, paginate.value)
    };
    const handleUpdateSize = (event) => {
      paginate.value.paginationPage = 0;
      paginate.value.paginationSize = Number(event);
      loadReport(props.code, filter.value, paginate.value)
    };

    loadInputs(props.code);
    loadReport(props.code, filter.value, paginate.value);
    return {
      loadReport,
      filter,
      reportData,
      loading,
      ...CompanySelect(),
      handleUpdateSize,
      handleUpdatePage,
      paginate,
    }
  },
  methods: {
    onExport() {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      ReportService.export(this.code, this.filter).then(res => {
        makeDownloadLink(res);
      }).catch(() => {
      }).finally(() => {
        Swal.close();
      })
    },
    onDiscard() {
      this.filter = this.filter.map(o => ({...o, value: ''}))
      this.paginate.paginationPage = 1
      this.loadReport(this.code, this.filter,this.paginate);
    },
    onFilter() {
      this.paginate.paginationPage = 1
      this.loadReport(this.code, this.filter, this.paginate);
    }
  }
})
